import { Properties, sanitizeUser, User, ValidPropertyValueType } from "../../../core/internal/model/model"
import { IStorage } from "../../../core/internal/storage/Storage"
import { UserListener } from "../../../core/internal/user/UserListener"
import { isSameUser, mergeUsers, UserManager } from "../../../core/internal/user/UserManager"
import ObjectUtil from "../../../core/internal/util/ObjectUtil"
import { DEVICE_ID_STORAGE_KEY, USER_ID_STORAGE_KEY } from "../../../config"

export class UserManagerImpl implements UserManager {
  private userListeners: UserListener[] = []
  private readonly defaultUser: User = { deviceId: this.hackleDeviceId }
  private _currentUser: User = this.defaultUser

  constructor(private storage: IStorage, private hackleDeviceId: string) {
    this.initialize()
  }

  public addListener(listener: UserListener): void {
    this.userListeners.push(listener)
  }

  public initialize() {
    const deviceId = this.storage.getItem(DEVICE_ID_STORAGE_KEY) || this.hackleDeviceId
    const userId = this.storage.getItem(USER_ID_STORAGE_KEY) || undefined

    this._currentUser = { deviceId, userId }
  }

  public get currentUser() {
    return this._currentUser
  }

  public resolveCurrentOrNull(user: User | string | undefined): User | null {
    if (user === undefined) {
      return this.currentUser
    }

    if (typeof user === "string") {
      return this.setUser({ id: user })
    }

    const sanitizedUser = sanitizeUser(user)
    if (sanitizedUser) {
      return this.setUser(sanitizedUser)
    } else {
      return null
    }
  }

  public setUser(user: User): User {
    const oldUser = this._currentUser
    const newUser = mergeUsers(oldUser, user)
    this._currentUser = newUser

    if (!isSameUser(oldUser, newUser)) {
      this.changeUser(oldUser, newUser, new Date().getTime())
      this.saveUser(newUser)
    }

    return newUser
  }

  public setUserId(userId: string | undefined): User {
    const user: User = {
      ...this._currentUser,
      userId
    }
    return this.setUser(user)
  }

  public setDeviceId(deviceId: string): User {
    const user: User = {
      ...this._currentUser,
      deviceId
    }
    return this.setUser(user)
  }

  public setUserProperty(key: string, value: ValidPropertyValueType): User {
    const user: User = {
      ...this._currentUser,
      properties: {
        ...this._currentUser.properties,
        [key]: value
      }
    }
    return this.setUser(user)
  }

  setUserProperties(properties: Properties): User {
    const user: User = {
      ...this._currentUser,
      properties: {
        ...this._currentUser.properties,
        ...properties
      }
    }
    return this.setUser(user)
  }

  public resetUser(): User {
    return this.setUser(this.defaultUser)
  }

  private changeUser(oldUser: User, newUser: User, timestamp: number) {
    this.userListeners.forEach((listener) => {
      listener.onUserUpdated(oldUser, newUser, timestamp)
    })
  }

  private saveUser(user: User) {
    if (ObjectUtil.isNotNullOrUndefined(user.deviceId)) {
      this.storage.setItem(DEVICE_ID_STORAGE_KEY, user.deviceId)
    } else {
      this.storage.removeItem(DEVICE_ID_STORAGE_KEY)
    }

    if (ObjectUtil.isNotNullOrUndefined(user.userId)) {
      this.storage.setItem(USER_ID_STORAGE_KEY, user.userId)
    } else {
      this.storage.removeItem(USER_ID_STORAGE_KEY)
    }
  }
}
