import { Double, EventProperties, Identifiers, Long, Properties } from "../model/model"
import Event from "./Event"

export interface EventPayloadDto {
  exposureEvents: ExposureEventDto[]
  trackEvents: TrackEventDto[]
  remoteConfigEvents: RemoteConfigEventDto[]
}

export interface EventDto {
  insertId: string
  timestamp: Long

  userId?: string
  identifiers: Identifiers
  userProperties: Properties
  hackleProperties: Properties
}

export interface ExposureEventDto extends EventDto {
  experimentId: Long
  experimentKey: Long
  experimentType: string
  experimentVersion: Long
  variationId?: Long
  variationKey: string
  decisionReason: string
  properties: EventProperties
}

export interface TrackEventDto extends EventDto {
  eventTypeId: Long
  eventTypeKey: string
  value: Double
  properties: EventProperties
}

export interface RemoteConfigEventDto extends EventDto {
  parameterId: Long
  parameterKey: string
  parameterType: string
  decisionReason: string
  valueId?: Long
  properties: EventProperties
}

export function toPayload(events: Array<Event | EventDto>): EventPayloadDto {
  const exposures = new Array<ExposureEventDto>()
  const tracks = new Array<TrackEventDto>()
  const remoteConfigs = new Array<RemoteConfigEventDto>()

  events.forEach((event) => {
    if (event instanceof Event) {
      if (Event.isExposure(event)) {
        exposures.push(event.toDto())
      } else if (Event.isTrack(event)) {
        tracks.push(event.toDto())
      } else if (Event.isRemoteConfig(event)) {
        remoteConfigs.push(event.toDto())
      }
    } else {
      if (Event.isExposureDto(event)) {
        exposures.push(event)
      } else if (Event.isTrackDto(event)) {
        tracks.push(event)
      } else if (Event.isRemoteConfigDto(event)) {
        remoteConfigs.push(event)
      }
    }
  })

  return {
    exposureEvents: exposures,
    trackEvents: tracks,
    remoteConfigEvents: remoteConfigs
  }
}
