import { v4 as uuid4 } from "uuid"
import {
  DecisionReason,
  EventProperties,
  EventType,
  Experiment,
  HackleEvent,
  HackleUser,
  IdentifierType,
  Long,
  RemoteConfigParameter,
  VariationId,
  VariationKey
} from "../model/model"
import { EventDto, ExposureEventDto, RemoteConfigEventDto, TrackEventDto } from "./dto"
import { Evaluation, RemoteConfigEvaluation } from "../evaluation/Evaluator"
import PropertyUtil from "../util/PropertyUtil"
import ObjectUtil from "../util/ObjectUtil"

export default abstract class Event {
  timestamp: Long
  user: HackleUser
  public readonly insertId: string

  constructor(timestamp: Long, user: HackleUser, insertId: string = uuid4()) {
    this.timestamp = timestamp
    this.user = user
    this.insertId = insertId
  }

  static exposure(experiment: Experiment, user: HackleUser, evaluation: Evaluation) {
    return new Exposure(
      new Date().getTime(),
      user,
      experiment,
      evaluation.variationId,
      evaluation.variationKey,
      evaluation.reason,
      this.exposureProperties(evaluation)
    )
  }

  private static exposureProperties(evaluation: Evaluation): EventProperties {
    if (ObjectUtil.isNotNullOrUndefined(evaluation.config)) {
      return {
        $parameterConfigurationId: evaluation.config.id
      }
    } else {
      return {}
    }
  }

  static track(eventType: EventType, event: HackleEvent, user: HackleUser, timestamp: number) {
    return new Track(timestamp, user, eventType, event)
  }

  static remoteConfig(
    remoteConfigParameter: RemoteConfigParameter,
    user: HackleUser,
    evaluation: RemoteConfigEvaluation
  ): RemoteConfig {
    return new RemoteConfig(new Date().getTime(), user, remoteConfigParameter, evaluation)
  }

  static isExposure(event: Event): event is Exposure {
    return (event as Exposure).experiment !== undefined
  }

  static isTrack(event: Event): event is Track {
    return (event as Track).eventType !== undefined
  }

  static isRemoteConfig(event: Event): event is RemoteConfig {
    return (event as RemoteConfig).remoteConfigParameter !== undefined
  }

  static isExposureDto(event: EventDto): event is ExposureEventDto {
    return (
      "experimentId" in (event as ExposureEventDto) && typeof (event as ExposureEventDto).experimentId !== "undefined"
    )
  }

  static isTrackDto(event: EventDto): event is TrackEventDto {
    return "eventTypeId" in (event as TrackEventDto) && typeof (event as TrackEventDto).eventTypeId !== "undefined"
  }

  static isRemoteConfigDto(event: EventDto): event is RemoteConfigEventDto {
    return (
      "parameterId" in (event as RemoteConfigEventDto) &&
      typeof (event as RemoteConfigEventDto).parameterId !== "undefined"
    )
  }

  abstract copyWithUser(user: HackleUser): Event

  toDto(): EventDto {
    return {
      insertId: this.insertId,
      timestamp: this.timestamp,

      userId: this.user.identifiers[IdentifierType.ID],
      identifiers: this.user.identifiers,
      userProperties: PropertyUtil.filteredProperties(this.user.properties),
      hackleProperties: PropertyUtil.filteredProperties(this.user.hackleProperties)
    }
  }
}

export class Exposure extends Event {
  experiment: Experiment
  variationId?: VariationId
  variationKey: VariationKey
  decisionReason: DecisionReason
  properties: EventProperties

  constructor(
    timestamp: Long,
    user: HackleUser,
    experiment: Experiment,
    variationId: VariationId | undefined,
    variationKey: VariationKey,
    decisionReason: DecisionReason,
    properties: EventProperties,
    insertId?: string
  ) {
    super(timestamp, user, insertId)
    this.experiment = experiment
    this.variationId = variationId
    this.variationKey = variationKey
    this.decisionReason = decisionReason
    this.properties = properties
  }

  copyWithUser(user: HackleUser): Exposure {
    return new Exposure(
      this.timestamp,
      user,
      this.experiment,
      this.variationId,
      this.variationKey,
      this.decisionReason,
      this.properties,
      this.insertId
    )
  }

  toDto(): ExposureEventDto {
    return {
      ...super.toDto(),

      experimentId: this.experiment.id,
      experimentKey: this.experiment.key,
      experimentType: this.experiment.type,
      experimentVersion: this.experiment.version,
      variationId: this.variationId,
      variationKey: this.variationKey,
      decisionReason: this.decisionReason.toString(),
      properties: this.properties
    }
  }
}

export class Track extends Event {
  eventType: EventType
  event: HackleEvent

  constructor(timestamp: Long, user: HackleUser, eventType: EventType, event: HackleEvent, insertId?: string) {
    super(timestamp, user, insertId)
    this.eventType = eventType
    this.event = event
  }

  copyWithUser(user: HackleUser): Track {
    return new Track(this.timestamp, user, this.eventType, this.event, this.insertId)
  }

  toDto(): TrackEventDto {
    return {
      ...super.toDto(),

      eventTypeId: this.eventType.id,
      eventTypeKey: this.eventType.key,
      value: this.event.value || 0,
      properties: PropertyUtil.filteredProperties(this.event.properties || {})
    }
  }
}

export class RemoteConfig extends Event {
  remoteConfigParameter: RemoteConfigParameter
  evaluation: RemoteConfigEvaluation

  constructor(
    timestamp: Long,
    user: HackleUser,
    remoteConfigParameter: RemoteConfigParameter,
    evaluation: RemoteConfigEvaluation,
    insertId?: string
  ) {
    super(timestamp, user, insertId)
    this.remoteConfigParameter = remoteConfigParameter
    this.evaluation = evaluation
  }

  copyWithUser(user: HackleUser): RemoteConfig {
    return new RemoteConfig(this.timestamp, user, this.remoteConfigParameter, this.evaluation, this.insertId)
  }

  toDto(): RemoteConfigEventDto {
    return {
      ...super.toDto(),

      parameterId: this.remoteConfigParameter.id,
      parameterKey: this.remoteConfigParameter.key,
      parameterType: this.remoteConfigParameter.type,
      valueId: this.evaluation.valueId,
      decisionReason: this.evaluation.reason.toString(),
      properties: PropertyUtil.filteredProperties(this.evaluation.properties)
    }
  }
}
